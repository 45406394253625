/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React from 'react';

import Hero from 'components/pages/blog/hero';
import PostsBoard from 'components/pages/blog/posts-board';
import CTA from 'components/shared/cta';
import MainLayout from 'layouts/main';

const Blog = ({
  data: {
    wpPage: {
      seo,
      blogFields: { hero, quotation, cta },
    },
    allWpPost: { nodes: allPosts },
  },
  pageContext,
}) => {
  const ctaData = {
    title: cta.title,
    buttonText: cta.button.title,
    buttonUrl: cta.button.url,
    marginTop: cta.marginTop,
  };
  return (
    <MainLayout seo={seo} pageContext={pageContext} headerTheme="grey-5">
      <Hero {...hero} />
      <PostsBoard quotation={quotation} items={allPosts} />
      <CTA {...ctaData} />
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      blogFields {
        hero {
          title
          description
        }
        quotation {
          title
          description
          avatar {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 101)
              }
            }
          }
          name
        }
        cta {
          title
          button {
            url
            title
          }
          marginTop
        }
      }
      ...wpPageSeo
    }
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        title
        date(formatString: "MMMM DD, yyyy")
        blogPostFields {
          summary
        }
        url: uri
      }
    }
  }
`;

export default Blog;
