import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const sizeClassNames = {
  sm: 'h-12',
  md: 'h-[56px]',
};

const Input = ({ className: additionalClassName, isInvalid, size, ...otherProps }) => (
  <input
    className={classNames(
      'remove-autocomplete-styles appearance-none rounded-none flex w-full border bg-primary-white px-4 outline-none text-base transition-colors duration-200 placeholder-grey-20',
      sizeClassNames[size],
      isInvalid ? 'border-state-error' : 'border-grey-60 focus:border-secondary-blue',
      additionalClassName
    )}
    {...otherProps}
  />
);

Input.propTypes = {
  className: PropTypes.string,
  isInvalid: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(sizeClassNames)),
};

Input.defaultProps = {
  className: null,
  isInvalid: false,
  size: 'sm',
};

export default Input;
