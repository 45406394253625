import classNames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import Heading from 'components/shared/heading';
import Quote from 'icons/quote.inline.svg';

const Quotation = ({ className, title, description, avatar, name }) => (
  <aside className={classNames(className, 'px-6 pt-6 border-2 border-grey-10')}>
    <Quote className="w-[50px] h-auto md:w-10" />
    <Heading
      className="mt-5 with-highlighed-text-gradient-secondary-pink flat-none lg:flat-breaks"
      tag="h3"
      size="md"
      theme="primary-black"
      asHTML
    >
      {title}
    </Heading>
    <p className="text-lg mt-4">{description}</p>
    <div className="flex mt-5 space-x-4 items-center">
      <GatsbyImage image={getImage(avatar.localFile)} alt={name} />
      <div className="leading-tight" dangerouslySetInnerHTML={{ __html: name }} />
    </div>
  </aside>
);

export const quotationPropTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  avatar: PropTypes.shape({
    localFile: PropTypes.shape({}),
  }).isRequired,
  name: PropTypes.string.isRequired,
};
Quotation.propTypes = {
  className: PropTypes.string,
  ...quotationPropTypes,
};

Quotation.defaultProps = {
  className: null,
};

export default Quotation;
