import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Image from 'components/shared/image';

import svgIllustration from './images/hero-illustration.svg';
import Subscribe from './subscribe';

const Hero = ({ title, description }) => {
  const { imageIllustration } = useStaticQuery(graphql`
    query {
      imageIllustration: file(relativePath: { eq: "pages/blog/hero/illustration.png" }) {
        childImageSharp {
          gatsbyImageData(width: 190)
        }
      }
    }
  `);

  const imageData = {
    width: 545,
    height: 420,
    imageSrc: svgIllustration,
    gatsbyImage: imageIllustration,
    gatsbyImageWidth: 190,
    gatsbyImageHeight: 236,
    gatsbyImageX: 334,
    gatsbyImageY: 40,
  };
  return (
    <section className="bg-grey-5 pt-2.5 lg:pt-10 pb-20 lg:pb-16 md:pb-12">
      <Container className="flex items-center justify-between space-x-20 lg:flex-col lg:space-x-0 lg:space-y-10">
        <div className="max-w-[488px] lg:max-w-2xl md:max-w-none pt-6 lg:pt-0">
          <Heading tag="h2" size="xl" theme="primary-black">
            {title}
          </Heading>
          <p className="text-lg mt-4">{description}</p>
          <Subscribe />
        </div>
        <div className="pr-12 xl:pr-0">
          <Image
            className={classNames('w-full lg:mx-auto max-w-[545px] xl:max-w-[450px]')}
            {...imageData}
          />
        </div>
      </Container>
    </section>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Hero;
