import classNames from 'classnames';
import React, { useState } from 'react';

import Button from 'components/shared/button';
import Input from 'components/shared/input';
import { subscribeToBlog } from 'utils/api';
import { validEmail } from 'utils/utils';

import './subscribe.css';

import CheckIcon from './images/subscription-check.inline.svg';

const FORM_STATES = {
  DEFAULT: 'default',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const Subscribe = () => {
  const [email, setEmail] = useState('');
  const [formState, setFormState] = useState(FORM_STATES.DEFAULT);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleInputChange = (event) => setEmail(event.currentTarget.value.trim());

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!email) {
      setErrorMessage('Please enter your email');
    } else if (!validEmail(email)) {
      setErrorMessage('Please enter a valid email');
    } else {
      setErrorMessage('');
      setFormState(FORM_STATES.LOADING);
      // add your form url here
      subscribeToBlog({ email })
        .then((response) => {
          if (response.ok) {
            setFormState(FORM_STATES.SUCCESS);
            setSuccessMessage(response.message);

            setTimeout(() => {
              setFormState(FORM_STATES.DEFAULT);
              setSuccessMessage('');
            }, 10000);
          } else {
            setFormState(FORM_STATES.ERROR);
            setErrorMessage(response.message);
          }
        })
        .catch(() => {
          setFormState(FORM_STATES.ERROR);
          setErrorMessage('Something went wrong. Please reload the page and try again');
        });
    }
  };
  return (
    <form className="w-full flex mt-7 xs:flex-col xs:space-y-7" noValidate onSubmit={handleSubmit}>
      <div className="relative w-full">
        <Input
          size="md"
          className={classNames(
            'border-r-0 xs:border-r focus:border-r-0 xs:focus:border-r',
            errorMessage && '!border-r-0 xs:!border-r',
            successMessage && 'green-border'
          )}
          type="email"
          name="email"
          label="xD"
          isInvalid={!!errorMessage}
          placeholder="Your email"
          autoComplete="email"
          value={email}
          onChange={handleInputChange}
        />
        {errorMessage && (
          <span className="text-xs absolute -bottom-1 left-0 translate-y-full leading-none text-state-error sm:text-[10px]">
            {errorMessage}
          </span>
        )}
        {successMessage && (
          <span className="text-xs absolute -bottom-1 left-0 translate-y-full leading-none text-success sm:text-[10px]">
            {successMessage}
          </span>
        )}
      </div>
      <Button
        size="lg"
        type="submit"
        theme="primary-black-filled"
        className={classNames(
          'shrink-0 w-[140px] xs:w-full',
          (formState === FORM_STATES.LOADING || formState === FORM_STATES.SUCCESS) &&
            'pointer-events-none'
        )}
      >
        {(formState === FORM_STATES.DEFAULT || formState === FORM_STATES.ERROR) && (
          <span>Subscribe</span>
        )}
        {formState === FORM_STATES.LOADING && (
          <span className="h-5 w-5 animate-spin rounded-full border border-b border-transparent border-b-white" />
        )}
        {formState === FORM_STATES.SUCCESS && <CheckIcon className="w-5" />}
      </Button>
    </form>
  );
};

export default Subscribe;
