import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Link from 'components/shared/link';

import Quotation, { quotationPropTypes } from '../quotation/quotation';

const PostsBoard = ({ items, quotation }) => (
  <section className="mt-20 lg:mt-16 md:mt-12">
    <Container className="flex space-x-16 justify-between lg:flex-col-reverse lg:space-x-0">
      <div className="max-w-[760px] lg:mt-10 lg:max-w-none">
        {items.map(({ date, url, title, blogPostFields: { summary } }, index) => (
          <article className="py-8 first:pt-0 border-b border-dashed border-grey-30" key={index}>
            <span className="text-grey-60 inline-block">{date}</span>
            <Link className="mt-3 block" to={url}>
              <Heading tag="h3" size="sm" theme="primary-black">
                {title}
              </Heading>
            </Link>
            <div className="mt-3 text-lg" dangerouslySetInnerHTML={{ __html: summary }} />
          </article>
        ))}
      </div>
      <Quotation className="self-start max-w-[384px] lg:max-w-none" {...quotation} />
    </Container>
  </section>
);

PostsBoard.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      blogPostFields: PropTypes.shape({
        summary: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  quotation: PropTypes.shape({
    ...quotationPropTypes,
  }).isRequired,
};

PostsBoard.defaultProps = {};

export default PostsBoard;
